import React from "react"
import Navigation from "../components/Navigation"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"

import HeaderImg from "../images/main_banner.jpg"
import CovidBannner from "../images/banner.png"
import GroundImg from "../images/ground.jpg"
import ProcessImg from "../images/process.jpg"
import GatewayImg from "../images/gateway.jpg"
import P2pImg from "../images/p2p.jpg"
import VisaImg from "../images/visa.jpg"
import ProfitImg from "../images/profit.png"

const IndexPage = () => (
  <Layout>
    <SEO title="SLS Panamá" />

    <div
      className="top_header pb-20"
      style={{
        backgroundImage: `url(${HeaderImg})`,
        backgroundColor: `#ddd`,
        backgroundSize: `cover`,
      }}
    >
      <Navigation />

      <div className="w-full lg:flex">
        <div
          className="w-full lg:w-1/5 ml-0 lg:ml-20 lg:mt-20 mt-10 lg:mt-0"
          id="covidBanner text-center"
        >
          <img
            src={CovidBannner}
            className="mx-auto"
            style={{ width: "90%" }}
          />
        </div>
        <div className="w-10/12 lg:w-1/5 ml-5 lg:ml-20">
          <ul class="minerva text-3xl text-white list-none lg:list-disc mt-20">
            <li>e-Commerce from the Ground Up</li>
            <li>Process Automation</li>
            <li>SECURE gateway services</li>
            <li>P2P PAYMENT NETWORK</li>
            <li>VISA CARD</li>
          </ul>
        </div>
      </div>
    </div>

    <div className="w-full lg:flex">
      <div
        className="ground w-full lg:w-1/2 "
        style={{
          backgroundImage: `url(${GroundImg})`,
          backgroundColor: `#222`,
          backgroundSize: `cover`,
          backgroundPosition: `right`,
          paddingTop: "60px",
          paddingBottom: "60px",
        }}
      >
        <div className="copy lg:ml-20">
          <h2 className="minerva text-3xl lg:text-5xl text-white ml-5 lg:ml-0">
            e-Commerce from the Ground Up
          </h2>
          <ul className="minerva text-2xl lg:text-3xl text-white list-none lg:list-disc mt-10 lg:mt-20 ml-5 lg:ml-5">
            <li>Domain Registration</li>
            <li>Web Development</li>
            <li>Email Deployment</li>
            <li>Security</li>
            <li>Payment Processing</li>
            <li>Order Fulfillment</li>
            <li>Inventory Management</li>
            <li>Tracking</li>
            <li>Customer Service</li>
            <li>Search Engine Optimization (SEO)</li>
            <li>Advertising and Marketing</li>
          </ul>
        </div>
      </div>

      <div
        className="ground w-full lg:w-1/2 "
        style={{
          backgroundImage: `url(${ProcessImg})`,
          backgroundColor: `#222`,
          backgroundSize: `cover`,
          backgroundPosition: `right`,
          paddingTop: "60px",
          paddingBottom: "60px",
        }}
      >
        <div className="copy lg:ml-20">
          <h2 className="minerva text-3xl lg:text-5xl text-white ml-5 lg:ml-0">
            Process Automation
          </h2>
          <ul className="minerva text-2xl lg:text-3xl text-white list-none lg:list-disc mt-10 lg:mt-20 ml-5  lg:ml-5">
            <li>Domain Registration</li>
            <li>Web Development</li>
            <li>Email Deployment</li>
            <li>Security</li>
            <li>Payment Processing</li>
            <li>Order Fulfillment</li>
            <li>Inventory Management</li>
            <li>Tracking</li>
            <li>Customer Service</li>
            <li>Search Engine Optimization (SEO)</li>
            <li>Advertising and Marketing</li>
          </ul>
        </div>
      </div>
    </div>

    <div className="w-full lg:flex">
      <div
        className="ground w-full lg:w-1/2 "
        style={{
          backgroundImage: `url(${GatewayImg})`,
          backgroundColor: `#222`,
          backgroundSize: `cover`,
          paddingTop: "60px",
          paddingBottom: "60px",
        }}
      >
        <div className="copy lg:ml-20">
          <h2 className="minerva text-3xl lg:text-5xl text-white ml-5 lg:ml-0">
            SECURE online gateway services
          </h2>
          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5 mr-5">
            Users can be reached globally, everyone can easily sell or buy all
            around the world, for individuals and corporations online
            transaction can be difficult to settle.{" "}
          </p>

          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5 mr-5">
            Security is important, to avoid fraud and protect and manage
            information.
          </p>

          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5 mr-5">
            Ainely S.A. (Uruguay) , part of the SLS Family, integrated the tools
            required in the form of Golden Gate Application Programming
            Interface®.{" "}
          </p>

          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5 mr-5">
            SLS S.A. Provides a Gateway Software for e-Commerce, To Clear the
            Sell and Refunds on the PNP Acquirers or develop a customized PNP.
          </p>
        </div>
      </div>

      <div
        className="ground w-full lg:w-1/2 "
        style={{
          backgroundImage: `url(${P2pImg})`,
          backgroundColor: `#222`,
          backgroundSize: `cover`,
          paddingTop: "60px",
          paddingBottom: "60px",
        }}
      >
        <div className="copy lg:ml-20">
          <h2 className="minerva text-3xl lg:text-5xl text-white ml-5 lg:ml-0">
            Peer-to-Peer Network “P2P”
          </h2>
          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5 mr-5">
            SLS EBRICK.® Our Payment Network for P2P transactions. Powered by
            Ainely´s Golden Gate Application Programming Interface® allows
            individuals to access the convenience of E-commerce to Clear the
            Sales and Refunds on major PNP´s. (Visa-MC){" "}
          </p>
        </div>
      </div>
    </div>

    <div className="w-full">
      <div
        className="ground w-full lg:flex"
        style={{
          backgroundImage: `url(${VisaImg})`,
          backgroundColor: `#222`,
          backgroundSize: `cover`,
          paddingTop: "60px",
          paddingBottom: "60px",
        }}
      >
        <div className="w-full lg:w-1/3 lg:ml-20">
          <h2 className="minerva text-3xl lg:text-5xl text-white ml-5 lg:ml-0">
            <img src={ProfitImg} alt="Profit Visa Card" />
          </h2>
        </div>
        <div className="w-full lg:w-2/3 lg:ml-20">
          <h2 className="minerva text-3xl lg:text-5xl text-white ml-5 lg:ml-0">
            Visa® card
          </h2>
          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5">
            SLS with its strategic allies; FC Medios de Pago, ProfitPro Versatec
            and Visa® can provide registered users* with VISA® Card Solutions.
            KYC Clear Fees to registered cardholders, Access to Balance and Fees
            on line.{" "}
          </p>

          <p className="minerva text-2xl text-white mt-6 lg:mt-10 lg:ml-1 ml-5">
            *All cardholders must pass the KYC and Origins of Funds must be
            properly stablished and all information provided
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
